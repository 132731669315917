import React from 'react';
import styled from 'styled-components';
import { FaBatteryHalf, FaBrain, FaBook, FaChild, FaHeartbeat, FaCogs } from 'react-icons/fa';

const Section = styled.section`
  padding: 50px 0;
`;

const Title = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-size: 1.2em;
`;

const IconWrapper = styled.div`
  margin-right: 15px;
  font-size: 2em;
`;

function Features() {
  return (
    <Section>
      <Title>Features</Title>
      <FeatureList>
        <FeatureItem>
          <IconWrapper><FaBatteryHalf /></IconWrapper>
          <span><strong>Emotional Disposition:</strong> Ellie’s mood is tied to its battery charge level and learning activities.</span>
        </FeatureItem>
        <FeatureItem>
          <IconWrapper><FaBrain /></IconWrapper>
          <span><strong>Interactive Learning:</strong> Users teach Ellie new information, improving her knowledge and mood.</span>
        </FeatureItem>
        <FeatureItem>
          <IconWrapper><FaBook /></IconWrapper>
          <span><strong>Knowledge Graph:</strong> Ellie learns and retains information only when explicitly taught by the user.</span>
        </FeatureItem>
        <FeatureItem>
          <IconWrapper><FaChild /></IconWrapper>
          <span><strong>Customizable Responses:</strong> Ellie’s responses adjust based on the user’s age, making her suitable for all ages.</span>
        </FeatureItem>
        <FeatureItem>
          <IconWrapper><FaHeartbeat /></IconWrapper>
          <span><strong>Companion-Like Bond:</strong> Similar to a Tamagotchi, Ellie creates a unique bond with her users.</span>
        </FeatureItem>
        <FeatureItem>
          <IconWrapper><FaCogs /></IconWrapper>
          <span><strong>Web Dashboard:</strong> Full visibility and control over Ellie’s settings and content.</span>
        </FeatureItem>
      </FeatureList>
    </Section>
  );
}

export default Features;
