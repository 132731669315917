import React, { useEffect } from 'react';
import Overview from './components/Overview';
import Demo from './components/Demo';
import Benefits from './components/Benefits';  // Benefits component before Features
import HelpSociety from './components/HelpSociety'; // Help Society component before Features
import Features from './components/Features';
import Signup from './components/Signup';
import Footer from './components/Footer';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: 0 20px;
`;

function App() {
  useEffect(() => {
    if (window.location.protocol !== 'https:' && window.location.hostname !== 'localhost') {
      window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
    }
  }, []);

  return (
    <Container>
      <Overview />
      <Demo />
      <Benefits /> 
      <HelpSociety /> 
      <Features />
      <Signup />
      <Footer />
    </Container>
  );
}

export default App;
