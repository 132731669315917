import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 50px 0;
  background-color: #f0f0f0;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 2.5em;
  margin-bottom: 20px;
`;

const Content = styled.div`
  max-width: 600px;
  margin: 0 auto;
  font-size: 1.2em;
  line-height: 1.6;
  text-align: left;
`;

const RulesList = styled.ul`
  margin-top: 20px;
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
`;

const SubpointHeader = styled.span`
  font-weight: bold;
`;

function HelpSociety() {
  return (
    <Section>
      <Title>Help Society</Title>
      <Content>
        <p>Engage in a unique social game with Ellie to contribute to Alzheimer’s prevention research while having fun and learning!</p>
        <RulesList>
          <li>
            <SubpointHeader>Weekly Trivia Challenge:</SubpointHeader> Each week, Ellies around the world compete in a trivia challenge based on the facts they have learned from their users.
          </li>
          <li>
            <SubpointHeader>Teach Your Ellie:</SubpointHeader> The more you teach Ellie from sources like Wikipedia, the better she performs in the trivia challenge. Help your Ellie learn and grow to increase her chances of winning.
          </li>
          <li>
            <SubpointHeader>Win Prizes:</SubpointHeader> The top-performing Ellies in each week's challenge win cash prizes equal to half of the subscription fees collected that week.
          </li>
          <li>
            <SubpointHeader>Support a Cause:</SubpointHeader> The other half of the subscription fees goes directly to Alzheimer’s prevention research, ensuring that every game contributes to a meaningful cause.
          </li>
          <li>
            <SubpointHeader>Stay Engaged:</SubpointHeader> Weekly leaderboards and community recognition encourage regular participation and foster a fun, competitive spirit.
          </li>
        </RulesList>
        <p>Join Ellie and thousands of other users in this exciting game where learning, fun, and social good intersect. Your engagement helps Ellie learn and succeed while contributing to a world free of Alzheimer's disease.</p>
      </Content>
    </Section>
  );
}

export default HelpSociety;
