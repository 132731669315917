import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  padding: 50px 0;
`;

const Title = styled.h1`
  font-size: 2.5em;
`;

const Subtitle = styled.p`
  font-size: 1.2em;
  max-width: 800px;
  margin: 0 auto;
`;

function Overview() {
  return (
    <Section>
      <Title>Meet Ellie: Your AI Learning Companion</Title>
      <Subtitle>
        Ellie is a revolutionary AI learning companion encased in a simple, elegant box with no screens or ports — just a button, speakers, and wireless charging. Ellie listens only when the user presses the button, like a walkie-talkie. Learn by teaching Ellie and watch her mood change as she learns new information. Ellie is perfect for all ages and creates a unique bond with her user friends.
      </Subtitle>
    </Section>
  );
}

export default Overview;
