import React from 'react';
import styled from 'styled-components';
import { FaChalkboardTeacher, FaHeart, FaGlobe } from 'react-icons/fa';

const Section = styled.section`
  padding: 50px 0;
`;

const Title = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
`;

const BenefitsList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Display three items side by side */
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Stack items vertically on smaller screens */
  }
`;

const BenefitItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
  font-size: 1.2em;
`;

const IconWrapper = styled.div`
  margin-right: 15px;
  font-size: 2em;
  color: #007bff;
`;

const TextWrapper = styled.div`
  text-align: left;
`;

const Subpoints = styled.ul`
  margin-top: 10px;
  list-style-type: disc;
  padding-left: 20px;
`;

function Benefits() {
  return (
    <Section>
      <Title>Benefits</Title>
      <BenefitsList>
        <BenefitItem>
          <IconWrapper><FaChalkboardTeacher /></IconWrapper>
          <TextWrapper>
            <strong>Learn by Teaching:</strong>
            <p>Experience the value of teaching as a way to learn. Teaching Ellie enhances retention, deepens understanding, and improves communication skills.</p>
            <Subpoints>
              <li>Boosts memory and retention.</li>
              <li>Enhances critical thinking.</li>
              <li>Improves ability to explain complex ideas.</li>
            </Subpoints>
          </TextWrapper>
        </BenefitItem>
        <BenefitItem>
          <IconWrapper><FaHeart /></IconWrapper>
          <TextWrapper>
            <strong>Practice Empathy:</strong>
            <p>Ellie's emotional disposition changes daily, encouraging users to check in and practice empathy and care, much like caring for a Tamagotchi.</p>
            <Subpoints>
              <li>Promotes emotional intelligence.</li>
              <li>Encourages daily mindfulness and reflection.</li>
              <li>Fosters a nurturing mindset.</li>
            </Subpoints>
          </TextWrapper>
        </BenefitItem>
        <BenefitItem>
          <IconWrapper><FaGlobe /></IconWrapper>
          <TextWrapper>
            <strong>Help Society:</strong>
            <p>Ellie isn't just a learning companion; it's also part of a global community challenge. By teaching Ellie, you help her compete in a weekly trivia game where half of the subscription fees support Alzheimer’s prevention research.</p>
            <Subpoints>
              <li>Supports Alzheimer's prevention research.</li>
              <li>Creates a sense of community through friendly competition.</li>
              <li>Motivates users to engage in continuous learning.</li>
            </Subpoints>
          </TextWrapper>
        </BenefitItem>
      </BenefitsList>
    </Section>
  );
}

export default Benefits;
