import React from 'react';
import styled from 'styled-components';

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  margin: 50px 0;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

function Demo() {
  return (
    <VideoWrapper>
      <Iframe
        // src="https://www.youtube.com/embed/videoseries?list=PL5j1ObCf7GgJFV689PSRLRs__DlN6j6Wm&autoplay=1"
        src="https://www.youtube.com/embed/k1ja8L64h0o"
        title="YouTube Demo Video"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></Iframe>
    </VideoWrapper>
  );
}

export default Demo;
