import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const Section = styled.section`
  padding: 50px 0;
  background-color: #f7f7f7;
`;

const Title = styled.h2`
  font-size: 2em;
  margin-bottom: 20px;
`;

const Form = styled.form`
  max-width: 500px;
  margin: 0 auto;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  font-size: 1em;
`;

const Button = styled.button`
  padding: 15px 20px;
  font-size: 1.2em;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

function Signup() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
  
    try {
      const response = await axios.post(
        'https://api.ask-ellie.com/signup/add-signup/',
        { name, email },
        { withCredentials: false } // Prevents CORS error
      );
  
      if (response.status === 200) {
        setSuccess(true);
        setName('');
        setEmail('');
      }
    } catch (err) {
      setError('Failed to sign up. Please try again.');
    }
  };
  

  return (
    <Section>
      <Title>Sign Up to Express Interest</Title>
        <p>A limited quantity of beta devices will be produced in Q4 2024. Sign up to be notified when you can order one!</p>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit">Sign Up Now</Button>
      </Form>
      {success && <p>Thank you for signing up! We'll keep you updated.</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </Section>
  );
}

export default Signup;
